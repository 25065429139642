// Generated by Framer (8a81902)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Embed from "https://framerusercontent.com/modules/o1PI5S8YtkA5bP5g4dFz/9zLIz4fn80IR9zpOx18Q/Embed.js";
import MOVEARROUND from "./hEzP9bRjt";
const MOVEARROUNDFonts = getFonts(MOVEARROUND);
const EmbedFonts = getFonts(Embed);

const enabledGestures = {I1B7gAkat: {hover: true, pressed: true}, T6lw5hpgn: {hover: true, pressed: true}};

const cycleOrder = ["T6lw5hpgn", "I1B7gAkat"];

const variantClassNames = {I1B7gAkat: "framer-v-1sgm8dw", T6lw5hpgn: "framer-v-da5l42"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "T6lw5hpgn", MOBILE: "I1B7gAkat"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "T6lw5hpgn"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "T6lw5hpgn", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-y9pPE", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-da5l42", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"T6lw5hpgn"} ref={ref} style={{backgroundColor: "var(--token-4830d250-406a-491a-9b60-e510365f5182, rgb(232, 232, 232))", ...style}} {...addPropertyOverrides({"I1B7gAkat-hover": {"data-framer-name": undefined}, "I1B7gAkat-pressed": {"data-framer-name": undefined}, "T6lw5hpgn-hover": {"data-framer-name": undefined}, "T6lw5hpgn-pressed": {"data-framer-name": undefined}, I1B7gAkat: {"data-framer-name": "MOBILE"}}, baseVariant, gestureVariant)}><motion.div className={"framer-kv7srj-container"} layoutDependency={layoutDependency} layoutId={"TJ42JwWCX-container"} style={{opacity: 1}} transformTemplate={transformTemplate} variants={{"I1B7gAkat-hover": {opacity: 0.21}, "I1B7gAkat-pressed": {opacity: 0}, "T6lw5hpgn-hover": {opacity: 0.21}, "T6lw5hpgn-pressed": {opacity: 0}}}><MOVEARROUND height={"100%"} id={"TJ42JwWCX"} layoutId={"TJ42JwWCX"} ll7H95XRb={"move arround"} width={"100%"}/></motion.div><motion.div className={"framer-15o8k8v-container"} layoutDependency={layoutDependency} layoutId={"cr1JkEhDe-container"}><Embed height={"100%"} html={""} id={"cr1JkEhDe"} layoutId={"cr1JkEhDe"} style={{height: "100%", width: "100%"}} type={"url"} url={"https://lumalabs.ai/embed/70322f50-b700-41d4-a1c8-c3deab17ad57?mode=sparkles&background=%23ffffff&color=%23000000&showTitle=true&loadBg=true&logoPosition=bottom-left&infoPosition=bottom-right&cinematicVideo=undefined&showMenu=false"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-y9pPE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-y9pPE .framer-j2jitu { display: block; }", ".framer-y9pPE .framer-da5l42 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 675px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", ".framer-y9pPE .framer-kv7srj-container { flex: none; height: auto; left: 50%; position: absolute; top: 567px; width: auto; z-index: 1; }", ".framer-y9pPE .framer-15o8k8v-container { flex: none; height: 687px; position: relative; width: 150%; }", ".framer-y9pPE .framer-v-da5l42 .framer-da5l42, .framer-y9pPE .framer-v-1sgm8dw .framer-da5l42 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-y9pPE .framer-da5l42 { gap: 0px; } .framer-y9pPE .framer-da5l42 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-y9pPE .framer-da5l42 > :first-child { margin-top: 0px; } .framer-y9pPE .framer-da5l42 > :last-child { margin-bottom: 0px; } }", ".framer-y9pPE.framer-v-1sgm8dw .framer-da5l42 { height: 628px; }", ".framer-y9pPE.framer-v-1sgm8dw .framer-kv7srj-container { top: 509px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 675
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"I1B7gAkat":{"layout":["fixed","fixed"]},"qYKPratYA":{"layout":["fixed","fixed"]},"u57F0QSYk":{"layout":["fixed","fixed"]},"PES6boDFz":{"layout":["fixed","fixed"]},"rGh1R_RUY":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerVFwb8cULg: React.ComponentType<Props> = withCSS(Component, css, "framer-y9pPE") as typeof Component;
export default FramerVFwb8cULg;

FramerVFwb8cULg.displayName = "About Description";

FramerVFwb8cULg.defaultProps = {height: 675, width: 1200};

addPropertyControls(FramerVFwb8cULg, {variant: {options: ["T6lw5hpgn", "I1B7gAkat"], optionTitles: ["Variant 1", "MOBILE"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerVFwb8cULg, [...MOVEARROUNDFonts, ...EmbedFonts])